

const UnAuthPage = ()=>{


    
    return <>
    
        <h1>You Dont Have Correct Authorization to this Page</h1>

        <a href="/">Login</a> <br /><br />
        <p>Create an Account  <a href="/sign-up">click me</a></p>

        {/* <a href=""></a> */}
    
    </>
}

export default UnAuthPage